<template>
  <v-app>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2">
        <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
        <v-card class="my-12 px-6">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="11">
              <div class="py-6">
                <div class="d-flex justify-center mb-9" style="font-size: 20px; font-weight: bold">
                  <span>住所を変更する</span>
                </div>
                <v-spacer></v-spacer>
                <template v-if="apierror.status == 'error'">
                  <div v-for="msg of apierror.messages" :key="msg">
                    <v-row class="ml-6 mb-3 ma-3">
                      <span style="color: red">* {{ msg }} </span>
                    </v-row>
                  </div>
                </template>
                <v-spacer></v-spacer>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="3">
                    <div>
                      <span v-if="!$vuetify.breakpoint.mdAndUp">本人住所</span>
                      <span v-if="$vuetify.breakpoint.mdAndUp" class="pl-3">本人住所</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="$v.Form.mbr_zip.$model"
                      :error-messages="mbr_zipErrors"
                      outlined
                      dense
                      color="#ff6e40"
                      placeholder="100001"
                      hide-details="auto"
                      :loading="loading"
                      @input="handleZipInput"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex justify-start"> </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="$v.Form.mbr_address1.$model"
                      :error-messages="mbr_address1Errors"
                      placeholder="市区町村番号(例：東京都千代田区大手町1-2-3)"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="$v.Form.mbr_address2.$model"
                      :error-messages="mbr_address2Errors"
                      placeholder="建物名・部屋番号"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-9">
                  <v-spacer></v-spacer>
                  <v-col cols="6" sm="3" class="d-flex justify-end">
                    <v-btn outlined width="100%" @click="back">
                      <span>取消</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" sm="3" class="d-flex justify-end">
                    <v-btn color="#ff6e40" width="100%" depressed @click="submit()">
                      <span style="color: #fff">変更</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import { helpers } from '@vuelidate/validators'
import Overlays from '@/components/Overlays.vue'

const validZip = helpers.regex(/^[0-9]{3}[-]{0,1}[0-9]{4}$/)
export default {
  components: {
    Overlays,
  },
  data: () => ({
    loading: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    Form: {
      mbr_zip: '',
      mbr_address1: '',
      mbr_address2: '',
    },
  }),
  validations: {
    Form: {
      mbr_zip: {
        required,
        validZip,
      },
      mbr_address1: {
        required,
        maxLength: maxLength(100),
      },
      mbr_address2: {
        maxLength: maxLength(100),
      },
    },
  },

  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('auth', ['info']),

    mbr_zipErrors() {
      const errors = []
      if (!this.$v.Form.mbr_zip.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_zip.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_zip.validZip && errors.push('数字7桁、半角―ハイフンなしで入力して下さい。')

      return errors
    },
    mbr_address1Errors() {
      const errors = []
      if (!this.$v.Form.mbr_address1.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_address1.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_address1.required && errors.push('必須項目')

      return errors
    },
    mbr_address2Errors() {
      const errors = []
      if (!this.$v.Form.mbr_address2.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_address2.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions

      return errors
    },
  },

  created() {
    this.setAbsoluteOverlayStatus(true)
    this.loadInfo().then(() => {
      this.Form.mbr_zip = this.info.mbr_zip
      this.Form.mbr_address1 = this.info.mbr_address1
      this.Form.mbr_address2 = this.info.mbr_address2
      this.setAbsoluteOverlayStatus(false)
    })
  },
  methods: {
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),
    ...mapActions('auth', ['ChangeAddress']),
    ...mapActions('auth', ['loadInfo']),

    handleZipInput(val) {
      if (val.length === 7) {
        this.getAddress()
      }
    },
    getAddress() {
      const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
      const url = api + this.Form.mbr_zip
      this.clearZipError()
      this.loading = true
      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.status === 400) {
            // エラー時
            this.Form.mbr_address1 = ''
            this.setZipError()
          } else if (data.results === null) {
            this.Form.mbr_address1 = ''
            this.setZipError()
          } else {
            this.Form.mbr_address1 = data.results[0].address1 + data.results[0].address2 + data.results[0].address3
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    setZipError() {
      this.apierror.messages = ['正しい郵便番号を入力してください']
      this.apierror.status = 'error'
      this.apierror.code = 'zip'
    },
    clearZipError() {
      this.apierror.messages = ''
      this.apierror.status = ''
      this.apierror.code = ''
    },
    submit() {
      this.$v.$touch()

      if (this.apierror.code === 'zip') {
        return
      }
      this.clearZipError()
      if (!this.$v.$invalid) {
        this.loading = true
        this.ChangeAddress(this.Form)
          .then(() => {
            this.back()
          })
          .catch(error => {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    back() {
      this.$router.push('/account')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
